// services.js

// const API_BASE_URL = "http://localhost:8000"; // Replace with your actual API base URL
export const API_BASE_URL = "https://tictactoe-api.sankalpmukim.dev"; // Replace with your actual API base URL

// Helper function to handle API responses
const handleResponse = async (response) => {
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "An error occurred");
  }
  return response.json();
};

// Create Game
export const createGame = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/create-game`, {
      method: "POST",
      credentials: "include",
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error creating game:", error);
    throw error;
  }
};

// Join Game
export const joinGame = async (gameCode) => {
  try {
    const response = await fetch(`${API_BASE_URL}/join-game`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ gameCode }),
      credentials: "include",
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error joining game:", error);
    throw error;
  }
};

// Get Game State
export const getGameState = async (gameCode) => {
  try {
    const response = await fetch(`${API_BASE_URL}/game-state/${gameCode}`, {
      credentials: "include",
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error getting game state:", error);
    throw error;
  }
};

// Refresh Session
export const refreshSession = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/refresh-session`, {
      method: "POST",
      credentials: "include",
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error refreshing session:", error);
    throw error;
  }
};

// Make Move
export const makeMove = async (gameCode, position) => {
  try {
    const response = await fetch(`${API_BASE_URL}/make-move/${gameCode}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ position }),
      credentials: "include",
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error making move:", error);
    throw error;
  }
};
