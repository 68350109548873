import { useEffect } from "react";
import useSocket from "../hooks/useSocket";
import Square from "./Square";

function GameScreen({
  gameCode,
  status,
  squares,
  isMyTurn,
  onSquareClick,
  onRefresh,
  onNewSession,
}) {
  const isGameOver =
    status.toLowerCase().includes("winner") ||
    status.toLowerCase().includes("draw");
  const socket = useSocket();
  useEffect(() => {
    socket?.on("new-move", (message) => {
      console.log("New move:", message);
      onRefresh();
    });
  }, [socket]);

  return (
    <div className="game">
      <div className="game-info">
        <div>Game Code: {gameCode}</div>
        <div>{status}</div>
        {isGameOver ? (
          <button onClick={onNewSession}>New Session</button>
        ) : (
          <>
            <div>
              <button onClick={onRefresh}>Refresh Game State</button>
            </div>
            <div>
              <button onClick={onNewSession}>Quit Game</button>
            </div>
          </>
        )}
      </div>
      <div className="game-board">
        {[0, 1, 2].map((row) => (
          <div key={row} className="board-row">
            {[0, 1, 2].map((col) => {
              const i = row * 3 + col;
              return (
                <Square
                  key={i}
                  value={squares[i]}
                  onSquareClick={() => onSquareClick(i)}
                  disabled={!isMyTurn || squares[i] !== "e"}
                />
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
}

export default GameScreen;
