import React, { useState, useEffect } from "react";
import {
  createGame,
  joinGame,
  getGameState,
  makeMove,
  refreshSession,
} from "./services";
import GameScreen from "./components/GameScreen";
import IntroScreen from "./components/IntroScreen";

export default function OnlineTicTacToe() {
  const [gameCode, setGameCode] = useState(null);
  const [squares, setSquares] = useState(Array(9).fill("e"));
  const [status, setStatus] = useState("");
  const [isCreator, setIsCreator] = useState(false);
  const [isMyTurn, setIsMyTurn] = useState(false);

  useEffect(() => {
    if (gameCode) {
      fetchGameState();
    }
  }, [gameCode]);

  const fetchGameState = async () => {
    try {
      const state = await getGameState(gameCode);
      updateGameState(state);
    } catch (error) {
      console.error("Error fetching game state:", error);
      setStatus("Failed to fetch game state. Please try refreshing.");
    }
  };

  const updateGameState = (state) => {
    setSquares(state.board.split(""));
    if (state.status === "FINISHED") {
      setStatus(
        state.winner === "draw" ? "It's a draw!" : `Winner: ${state.winner}`
      );
      setIsMyTurn(false);
    } else {
      const xCount = state.board.split("X").length - 1;
      const oCount = state.board.split("O").length - 1;
      const isCreatorTurn = xCount === oCount;
      const newIsMyTurn =
        (isCreator && isCreatorTurn) || (!isCreator && !isCreatorTurn);
      setIsMyTurn(newIsMyTurn);
      setStatus(newIsMyTurn ? "Your turn" : "Opponent's turn");
    }
  };

  const handleCreateGame = async () => {
    try {
      const result = await createGame();
      setGameCode(result.gameCode);
      setIsCreator(true);
      setIsMyTurn(true); // Creator always goes first
      setStatus("Waiting for opponent to join...");
    } catch (error) {
      console.error("Error creating game:", error);
      setStatus("Failed to create game. Please try again.");
    }
  };

  const handleJoinGame = async (joinCode) => {
    try {
      await joinGame(joinCode);
      setGameCode(joinCode);
      setIsCreator(false);
      setIsMyTurn(false); // Joiner goes second
      setStatus("Game joined. Waiting for creator to make a move...");
    } catch (error) {
      console.error("Error joining game:", error);
      setStatus("Failed to join game. Please check the code and try again.");
    }
  };

  const handleSquareClick = async (i) => {
    if (!isMyTurn || squares[i] !== "e") return;

    try {
      await makeMove(gameCode, i);
      await fetchGameState(); // Fetch the updated state immediately after making a move
    } catch (error) {
      console.error("Error making move:", error);
      setStatus("Failed to make move. Please try again.");
    }
  };

  const handleRefresh = () => {
    fetchGameState();
  };

  const handleRefreshSession = async () => {
    try {
      await refreshSession();
      // Reset all relevant React state
      setGameCode(null);
      setSquares(Array(9).fill("e"));
      setStatus("");
      setIsCreator(false);
      setIsMyTurn(false);
    } catch (error) {
      console.error("Error refreshing session:", error);
      setStatus("Failed to refresh session. Please try again.");
    }
  };

  if (!gameCode) {
    return (
      <IntroScreen
        onCreateGame={handleCreateGame}
        onJoinGame={handleJoinGame}
      />
    );
  }

  return (
    <GameScreen
      gameCode={gameCode}
      status={status}
      squares={squares}
      isMyTurn={isMyTurn}
      onSquareClick={handleSquareClick}
      onRefresh={handleRefresh}
      onNewSession={handleRefreshSession}
    />
  );
}
